<template>
  <div class="pageContol listWrap templateList formCom courseType1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考证分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox"></div>
          <div class="searchbox"></div>
          <div class="btnBox">
            <el-button class="bgc-bv" @click="courseType('add')"
              >新增考证分类</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              :data="tableData"
              :header-cell-style="tableHeader"
              height="100%"
              size="small"
              style="width: 100%"
              row-key="courseTypeId"
              @cell-mouse-enter="mouseenter"
              @cell-mouse-leave="mouseleave"
            >
              <el-table-column
                prop="certCategoryName"
                label="分类名称"
                align="left"
              ></el-table-column>
              <el-table-column label="显示/隐藏" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.isShow"
                    active-color="#ff4949"
                    inactive-color="#c0ccda"
                    @change="courseTypeChangehidden(scope.row)"
                  >
                  </el-switch>
                  <span style="margin-left: 5px">{{
                    !scope.row.isShow ? "隐藏" : "显示"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="sort" label="排序" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.sort }}</span>
                  <i
                    class="el-icon-edit"
                    style="margin-left: 10px"
                    v-if="mouseenterId == scope.row.certCategoryId"
                    @click="showOrderInput"
                  >
                  </i>
                  <el-input
                    type="number"
                    size="mini"
                    v-model="courseTypeOrderValue"
                    v-if="mouseenterIdclicked == scope.row.certCategoryId"
                    placeholder="请输入排序"
                    @change="changeOrder(scope.row)"
                    maxlength="3"
                    show-word-limit
                    oninput="value=value.replace(/[^\d.]/g,'')"
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div slot-scope="scope" class="">
                  <el-button
                    size="mini"
                    type="text"
                    @click="courseType('edit', scope.row)"
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
         <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      :title="courseTypeDialogTitle"
      :visible.sync="courseTypeDialogVisible"
      width="30%"
      center
      @close="courseTypeCancel"
    >
      <el-form
        ref="courseTypeDialogFromRef"
        :model="courseTypeDialogFrom"
        :rules="courseTypeDialogFromRules"
        label-width="120px"
      >
        <el-form-item label="分类名称" prop="certCategoryName" size>
          <el-input
            v-model="courseTypeDialogFrom.certCategoryName"
            maxlength="15"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="分类排序" prop="sort">
          <el-input
            v-model.number="courseTypeDialogFrom.sort"
            maxlength="3"
            show-word-limit
            oninput="value=value.replace(/[^\d.]/g,'')"
          ></el-input>
          <span style="font-size: 5px"
            >数字范围为0~255，数字越小越靠前。如果数字相同，先添加的排序靠前</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="courseTypeCancel">取 消</el-button>
        <el-button type="primary" @click="courseTypeAdd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
export default {
  name: "mini/courseType",
  mixins: [List],
  components: {
    PageNum,
  },
  data() {
    return {
      mouseenterId: "",
      mouseenterIdclicked:'',
      courseTypeOrderValue:'',
      courseTypeDialogVisible: false,
      courseTypeDialogTitle: "新增课程分类",
      courseTypeDialogFrom: {
        certCategoryName: "", //一级分类名称
        sort: "", //二级分类名称
      },
      courseTypeDialogFromRules: {
        certCategoryName: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入分类排序",
            trigger: "blur",
          },
          { type: "number", message: "分类排序必须为数字值" },
        ],
      },
    };
  },
  watch: {},
  filters: {
    hidden(value) {
      console.log(value);
      if (!value) {
        return "显示";
      }
      return "隐藏";
    },
  },
  created() {
    this.getData();
  },
  computed: {},
  methods: {
    validatePass(rule, value, callback) {
      if (value > 255) {
        callback(new Error("最大值应在255以内"));
      } else {
        callback();
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch(
        {
          url: "/minicert/minicategory/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //修改分类显示状态
    courseTypeChangehidden(info) {
      const params = {
        certCategoryId: info.certCategoryId,
        isShow: info.isShow,
      };
      this.$post("/minicert/minicategory/modify", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == 0 ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData(-1)
        })
        .catch(() => {
          return;
        });
    },
    // 增加分类弹窗
    courseType(stu, row) {
      this.courseTypeDialogVisible = true;
      if (stu == "add") {
        this.courseTypeDialogTitle = "新增考证分类";
      } else {
        this.courseTypeDialogTitle = "修改考证分类";
        this.courseTypeEditDialog(row.certCategoryId);
      }
    },
    //添加分类接口
    courseTypeAdd() {
      this.$refs["courseTypeDialogFromRef"].validate((valid) => {
        if (valid) {
          const params = {
            certCategoryName: this.courseTypeDialogFrom.certCategoryName,
            sort: this.courseTypeDialogFrom.sort,
          };
          if (this.courseTypeDialogFrom.certCategoryId) {
            params.certCategoryId = this.courseTypeDialogFrom.certCategoryId;
          }
          this.$post(
            this.courseTypeDialogFrom.certCategoryId
              ? "/minicert/minicategory/modify"
              : "/minicert/minicategory/insert",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message.success(res.message);
                this.courseTypeDialogVisible = false;
                this.courseTypeDialogFrom = {};
                this.$refs["courseTypeDialogFromRef"].resetFields();
                this.getData(-1);
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //取消
courseTypeCancel() {
this.courseTypeDialogFrom = {};
                this.$refs["courseTypeDialogFromRef"].resetFields();
                this.courseTypeDialogVisible = false;
},
    // 编辑分类弹窗
    courseTypeEditDialog(certCategoryId) {
      this.$post(
        "/minicert/minicategory/getInfo",
        { certCategoryId },
        3000,
        true,
        2
      ).then((ret) => {
        this.courseTypeDialogFrom = {
          ...this.courseTypeDialogFrom,
          ...ret.data,
        };
      });
    },

    //排序单元格 鼠标移入事件
    mouseenter(row, column, cell, event) {
      this.mouseenterId = row.certCategoryId;
    },
    //排序单元格 鼠标移除事件
    mouseleave(row, column, cell, event) {
      this.mouseenterIdclicked = this.mouseenterId = "";
    },
    //排序单元格 编辑图标 点击时间
    showOrderInput() {
      this.mouseenterIdclicked = this.mouseenterId;
    },
    //更改分类排序
    changeOrder(info) {
      const params = {
        certCategoryId: info.certCategoryId,
        sort: this.courseTypeOrderValue,
      };
      this.$post("/minicert/minicategory/modify", params, 3000, true, 2)
        .then((res) => {
          this.$message({
            type: res.status == "0" ? "success" : "error",
            message: res.message ? res.message : "操作失败",
          });
          this.getData();
          this.courseTypeOrderValue = "";
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
// .courseType1 {
//     .el-switch.is-checked .el-switch__core {
//         background-color: #c0ccda !important;
//         border-color: #c0ccda !important;
//     }
// }
</style>
